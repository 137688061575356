<template>
    <v-navigation-drawer
        v-model="show"
        temporary
        right
        app
        width="100%"
        stateless
    >
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
                width="1"
            ></v-progress-circular>
        </v-overlay>
        <template v-if="! loading && show">
            <v-card flat>
                <app-table
                    :caption="`${asset.title} Secrets`"
                    height="calc(100vh - 130px)"
                    :columns="columns"
                    :actions="tableActions"
                    :url="`companies/assets/${$route.params.assetId}/secrets`"
                    :params="tableParams"
                    ref="table"
                >
                    <template v-slot:actions>
                        <v-btn
                            small
                            color="primary"                            
                            class="ml-2"
                            :to="{
                                name: 'assets.secrets.create',
                                query: $route.query,
                                params: {
                                    assetId: $route.params.assetId,
                                }
                            }"
                            v-if="
                                $route.meta.parent === 'assets.secrets' ||
                                $route.name === 'assets.secrets'
                            "
                        >
                            <v-icon left dense>fal fa-plus</v-icon> Add
                        </v-btn>
                        <v-btn
                            small
                            color="red"
                            dark
                            class="ml-2"
                            @click="close"
                        >
                            <v-icon left dense>fal fa-times-circle</v-icon> Close
                        </v-btn>
                    </template>
                </app-table>
            </v-card>
            <add-modal @success="$refs.table.init()" />
            <edit-modal @success="$refs.table.init()" />
            <details-modal />
        </template>
    </v-navigation-drawer>
</template>

<script>
    import AppTable from '@/components/Table.vue';
    import Http from '@/utils/httpClient';
    import AddModal from './AddSecret.vue';
    import EditModal from './EditSecret.vue';

    export default {
        components: {
            AppTable,
            AddModal,
            EditModal,
            DetailsModal: () => import(/* webpackChunkName: 'assets.secrets.modals.details' */ './SecretDetails.vue'),
        },
        data() {
            return {
                show: false,
                loading: false,
                asset: {},
                from: null,
                columns: [
                    {
                        text: 'Title',
                        value: 'title',
                    },
                    {
                        text: 'Description',
                        value: 'description',
                    },
                    {
                        text: 'Max Versions',
                        value: 'max_versions',
                        width: 150,
                    },
                    {
                        text: 'Version',
                        value: 'version',
                        width: 130,
                    },
                ],
                tableParams: {
                    query: {
                        secret: [
                            'id',
                            'title',
                            'description',
                            'version',
                            'max_versions',
                        ]
                    }
                },
                tableActions: [
                    {
                        label: 'Details',
                        icon: 'fal fa-info-circle fa-fw',
                        handler: (secret) => {
                            this.$router.push({
                                name: 'assets.secrets.details',
                                query: this.$route.query,
                                params: {
                                    secretId: secret.id,
                                }
                            });
                        }
                    },
                    {
                        label: 'Edit',
                        icon: 'fal fa-pencil fa-fw',
                        handler: (secret) => {
                            this.$router.push({
                                name: 'assets.secrets.edit',
                                query: this.$route.query,
                                params: {
                                    secretId: secret.id,
                                }
                            });
                        }
                    },
                    {
                        label: 'Hard Delete',
                        icon: 'fal fa-trash-alt fa-fw',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to delete ${item.title} and all its versions permanently.`,
                                agreeButtonText: 'Yes, delete it permanently',
                                agreeButtonColor: 'error',
                            })
                            .then(() => {
                                this.$root.$loading.open({ text: `Deleting ${item.title} permanently...` });
                                Http.delete(`secrets/${item.id}/delete-metadata`)
                                    .then(() => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: `Secret ${item.title} was permanently deleted.`,
                                            type: 'success',
                                        });
                                        this.$refs.table.init();
                                    })
                                    .catch(() => this.$root.$loading.close());
                            })
                            .catch(() => {
                                this.$root.$snackbar.open({
                                    text: 'Action cancelled',
                                    type: 'info',
                                });
                            })
                        }
                    }
                ],
            }
        },
        watch: {
            $route(to, from) {
                this.from = from;
                const routes = [
                    'assets.secrets',
                    'assets.secrets.create',
                    'assets.secrets.edit',
                    'assets.secrets.details',
                ];

                if (!routes.includes(from.name)) {
                    this.init();
                }
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                const routes = [
                    'assets.secrets',
                    'assets.secrets.details',
                ];

                if (routes.includes(this.$route.name)) {
                    this.open();
                }
            },
            open() {
                this.show = true;
                this.loading = true;
                Http.get(`companies/assets/${this.$route.params.assetId}/show`, {
                    params: {
                        query: {
                            company_asset: [
                                'title',
                            ]
                        }
                    }
                })
                    .then(({ data }) => {
                        this.loading = false;
                        this.asset = data.data;
                    }).catch(() => {
                        this.loading = false;
                    });
            },
            close() {
                this.show = false;

                if (this.from) {
                    this.$router.push({
                        name: this.from.name,
                        query: this.$route.query,
                        params: this.from.params,
                    });
                } else {
                    this.$router.push({
                        name: this.$route.meta.parent,
                        query: this.$route.query,
                    });
                }
            },
        }
    }
</script>
