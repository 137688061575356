<template>
    <div>
        <app-table
            :caption="tableCaption"
            height="calc(100vh - 194px)"
            :columns="columns"
            :actions="tableActions"
            :url="tableUrl"
            ref="table"
            :params="tableParams"
        >
            <template v-slot:item.title="{ item }">
                <div class="tw-flex tw-items-center">
                    <span>{{ item.title }}</span>
                    <v-tooltip bottom v-if="item.secrets.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                x-small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                class="tw-ml-2"
                            >fal fa-key</v-icon>
                        </template>
                        <span>Secrets available</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:actions>
                <v-btn 
                    small 
                    class="ml-3" 
                    color="primary"
                    :to="{
                        name: 'assets.create',
                        query: $route.query,
                    }"
                >
                    <v-icon left dense>fal fa-plus</v-icon> Add
                </v-btn>
            </template>
        </app-table>
        <details-modal/>
        <secrets-modal/>
        <!--<add-modal @success="$refs.table.init()"/>
        <edit-modal @success="$refs.table.init()"/>-->
    </div>
</template>

<script>
    import Http from '@/utils/httpClient';
    import AppTable from '@/components/Table.vue';
    import DetailsModal from './modals/Details.vue';
    //import AddModal from './modals/Add.vue';
    //import EditModal from './modals/Edit.vue';
    import SecretsModal from './modals/Secrets.vue';

    export default {
        components: {
            AppTable,
            DetailsModal,
            //AddModal,
            //EditModal,
            SecretsModal,
        },
        data() {
            return {
                currentTab: 0,
                tabs: [],
                columns: [
                    {
                        text: 'Title',
                        value: 'title',
                        slotName: 'item.title',
                    },
                    {
                        text: 'Status',
                        value: 'status',
                    },
                ],
                tableParams: {
                    query: {
                        company_asset: [
                            'id',
                            'title',
                            'status',
                            'group_id',
                            'secrets',
                        ]
                    }
                },
                tableActions: [
                    {
                        label: 'Details',
                        icon: 'fal fa-info-circle fa-fw',
                        handler: (asset) => {
                            this.$router.push({
                                name: 'assets.details',
                                query: this.$route.query,
                                params: {
                                    assetId: asset.id,
                                }
                            });
                        }
                    },
                    {
                        label: 'Secrets',
                        icon: 'fal fa-key fa-fw',
                        handler: (asset) => {
                            this.$router.push({
                                name: 'assets.secrets',
                                query: this.$route.query,
                                params: {
                                    assetId: asset.id,
                                }
                            });
                        }
                    },
                    {
                        label: 'Edit',
                        icon: 'fal fa-pencil fa-fw',
                        handler: (asset) => {
                            this.$router.push({
                                name: 'assets.edit',
                                query: this.$route.query,
                                params: {
                                    assetId: asset.id,
                                }
                            });
                        }
                    },
                    {
                        label: 'Hard Delete',
                        icon: 'fal fa-trash-alt fa-fw',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to delete ${item.title} permanently.`,
                                agreeButtonText: 'Yes, delete it permanently',
                                agreeButtonColor: 'error',
                            })
                            .then(() => {
                                this.$root.$loading.open({ text: `Deleting ${item.title} permanently...` });
                                Http.delete(`companies/assets/${item.id}/hard-delete`)
                                    .then(() => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: `${item.title} was permanently deleted.`,
                                            type: 'success',
                                        });
                                        this.$refs.table.init();
                                    })
                                    .catch(() => this.$root.$loading.close());
                            })
                            .catch(() => {
                                this.$root.$snackbar.open({
                                    text: 'Action cancelled',
                                    type: 'info',
                                });
                            })
                        }
                    }                    
                ],
            }
        },
        computed: {
            tableCaption() {
                if (this.$route.query.group_title) {
                    return this.$route.query.group_title;
                }

                return 'All Assets';
            },
            tableUrl() {
                if (this.$route.query.group_id) {
                    return `companies/assets?group_id=${this.$route.query.group_id}`;
                }

                return 'companies/assets';
            }
        }
    }
</script>
