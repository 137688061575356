<template>
    <v-dialog
        v-model="show"
        persistent
        max-width="600"
        scrollable
    >
        <v-card>
            <v-card-title>Create secret for {{ asset.title }}</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="form.title"
                    :error-messages="form.errors.get('title')"
                    @input="form.errors.clear('title')"
                    label="Title (Required)"
                    autofocus
                />
                <v-text-field
                    type="number"
                    v-model="form.max_versions"
                    :error-messages="form.errors.get('max_versions')"
                    @input="form.errors.clear('max_versions')"
                    label="Maximum number of versions (Required)"
                />
                <h4 class="tw-font-normal tw-text-base tw-mb-2">Secrets</h4>
                <div
                    class="tw-flex tw-items-start"
                    v-for="(secret, index) in form.secrets"
                    :key="index"
                >
                    <div class="tw-mr-2 tw-flex-1">
                        <v-text-field
                            outlined
                            dense
                            label="Key"
                            v-model="form.secrets[index].key"
                            :error-messages="form.errors.get(`secrets.${index}.key`)"
                            @input="form.errors.clear(`secrets.${index}.key`)"
                        />
                    </div>
                    <div class="tw-flex-1">
                        <v-text-field
                            outlined
                            dense
                            label="Value"
                            v-model="form.secrets[index].value"
                            :error-messages="form.errors.get(`secrets.${index}.value`)"
                            @input="form.errors.clear(`secrets.${index}.value`)"
                        />
                    </div>
                    <v-btn
                        @click="deleteSecretField(index)"
                        class="tw-ml-2"
                        color="red"
                        fab
                        small
                        dark
                        v-if="form.secrets.length > 1"
                    ><v-icon small>fal fa-trash-alt</v-icon></v-btn>
                    <v-btn
                        @click="addSecretField"
                        class="tw-ml-2"
                        color="primary"
                        fab
                        small
                        dark
                        :style="{visibility: (index === (form.secrets.length - 1)) ? 'visible' : 'hidden'}"
                    ><v-icon small>fal fa-plus</v-icon></v-btn>
                </div>
                <v-textarea
                    v-model="form.description"
                    :error-messages="form.errors.get('description')"
                    @input="form.errors.clear('description')"
                    label="Description (Optional)"
                    rows="1"
                    auto-grow
                />
            </v-card-text>
            <v-card-actions class="pt-4">
                <v-spacer />
                <v-btn
                    text
                    :disabled="saving"
                    @click="close"
                >Cancel</v-btn>
                <v-btn
                    text
                    color="primary"
                    :loading="saving"
                    @click="save"
                >Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Form from '@/utils/form';
    import Http from '@/utils/httpClient';

    export default {
        data() {
            return {
                saving: false,
                show: false,
                asset: {},
                form: new Form({
                    title: null,
                    description: null,
                    max_versions: 10,
                    secrets: [],
                }),
            }
        },
        watch: {
            $route() {
                this.init();
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                if (this.$route.name === 'assets.secrets.create') {
                    this.open();
                }
            },
            close() {
                this.show = false;
                this.form.errors.clear();
                this.$router.push({
                    name: 'assets.secrets',
                    query: this.$route.query,
                });
            },
            open() {
                this.$root.$loading.open();
                Http.get(`companies/assets/${this.$route.params.assetId}/secrets/create`, {
                    params: {
                        query: {
                            company_asset: [
                                'title'
                            ]
                        }
                    }
                })
                    .then(({ data }) => {
                        this.$root.$loading.close();
                        this.asset = data.asset;
                        this.form.reset();
                        this.form.set({
                            secrets: [
                                {
                                    key: null,
                                    value: null,
                                }
                            ]
                        });
                        this.show = true;
                    })
                    .catch(() => {
                        this.close();
                        this.$root.$loading.close();
                    });
            },
            addSecretField() {
                this.form.secrets.push({
                    key: null,
                    value: null,
                });
            },
            deleteSecretField(index) {
                this.form.secrets.splice(index, 1);
            },
            save() {
                this.saving = true;
                Http.post(`companies/assets/${this.$route.params.assetId}/secrets`, this.form.payload())
                    .then(({ data }) => {
                        this.saving = false;
                        this.close();
                        this.$root.$snackbar.open({
                            text: `${this.form.title} secret was successfully created.`,
                            type: 'success',
                        });
                        this.$emit('success', data);
                    })
                    .catch(({ response }) => {
                        this.saving = false;
                        this.form.errors.record(response.data);
                    });
            }
        }
    }
</script>
