<template>
    <v-navigation-drawer
        v-model="show"
        temporary
        stateless
        right
        app
        width="1024"
    >
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
                width="1"
            ></v-progress-circular>
        </v-overlay>
        <template v-if="! loading">
            <v-card flat>
                <v-card-title class="mb-4 text-h6 tw-flex tw-items-start tw-flex-nowrap justify-space-between font-weight-light">
                    <div class="tw-break-words">{{ asset.title }}</div>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon dense>fal fa-times-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-card-title>
                <div class="v-data-table theme--light">
                    <div class="v-data-table__wrapper">
                        <table>
                            <tbody>
                                <tr>
                                    <td><b>Name</b></td>
                                    <td>{{ asset.name }}</td>
                                </tr>
                                <tr>
                                    <td><b>Status</b></td>
                                    <td>{{ asset.status }}</td>
                                </tr>
                                <tr>
                                    <td><b>Description</b></td>
                                    <td
                                        v-if="asset.description"
                                        v-html="asset.description"></td>
                                    <td v-else>--</td>
                                </tr>
                                <tr>
                                    <td><b>Secrets</b></td>
                                    <td>
                                        <div v-if="asset.secrets.length > 0" class="tw-flex tw-items-center tw-justify-between">
                                            <span>Available</span> <v-btn text outlined color="primary" rounded small @click="gotoSecrets"><v-icon left dense>fal fa-eye</v-icon> View</v-btn>
                                        </div>
                                        <div v-else class="tw-flex tw-items-center tw-justify-between">
                                            <span>No available secrets</span> <v-btn text outlined color="primary" rounded small @click="gotoSecrets"><v-icon left dense>fal fa-plus</v-icon> Add</v-btn>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="150"><b>Activated At</b></td>
                                    <td>{{ asset.activated_at_for_human || '--' }}</td>
                                </tr>
                                <tr>
                                    <td><b>Stale At</b></td>
                                    <td>{{ asset.stale_at_for_human || '--' }}</td>
                                </tr>
                                <tr v-for="(item, i) in asset.meta" :key="i">
                                    <td class="tw-capitalize"><b>{{ i }}</b></td>
                                    <td class="tw-whitespace-pre-line">{{ item }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </v-card>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import Http from '@/utils/httpClient';

    export default {
        data() {
            return {
                show: false,
                loading: false,
                customer: null,
                statement: null,
                asset: {
                    meta: {},
                    secrets: [],
                }
            }
        },
        watch: {
            $route() {
                this.init();
            },
            show(show) {
                if (!show) {
                    this.back();
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                const routes = [
                    'assets.details',
                ];

                if (routes.includes(this.$route.name)) {
                    this.open();
                }
            },
            open() {
                this.show = true;
                this.loading = true;
                Http.get(`companies/assets/${this.$route.params.assetId}/show`, {
                    params: {
                        query: {
                            company_asset: [
                                'id',
                                'title',
                                'name',
                                'status',
                                'meta',
                                'activated_at_for_human',
                                'stale_at_for_human',
                                'stale_at',
                                'secrets',
                                'description',
                                'customer_id',
                            ]
                        }
                    }
                })
                    .then(({ data }) => {
                        this.loading = false;
                        this.asset = data.data;
                    }).catch(() => {
                        this.loading = false;
                        this.close();
                    });
            },
            close() {
                this.show = false;
                this.back();
            },
            back() {
                this.$router.push({
                    name: 'assets.index',
                    query: this.$route.query,
                }).catch()
            },
            gotoSecrets() {
                this.$router.push({
                    name: 'assets.secrets',
                    query: this.$route.query,
                    params: {
                        assetId: this.asset.id,
                    }
                })
            }
        }
    }
</script>
